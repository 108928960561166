/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Section, Title, SectionButton } from "../../lib/utils"
import Product from "../general/Product"

const RECOMMENDED_MENU = [
  {
    id: 1,
    name: 'Babi Panggang Karo',
    price: 25000,
    description: 'Babi Panggang Karo (Pork Roasted) adalah makanan tradisional khas Medan. Komposisi hidangan ini berupa babi panggang karo, sayur daun ubi, cabe andaliman segar dan Sup B2 hangat.',
    imgID: 'MenuBPK',
  },
  {
    id: 2,
    name: 'Kidu-Kidu',
    price: 25000,
    description: 'Kidu-kidu adalah makanan tradisional khas Medan. Komposisi hidangan ini berupa Kidu-kidu, sayur daun ubi, cabe andaliman segar dan Sup B2 hangat.',
    imgID: 'MenuKidu',
  }
];

const RecomendedMenu = () => {
  return (
    <Section>
      <Title title="Populer" />
      <div>
        {/* in this case, each item has a node object, which is the way gatsby graphql returns... if you're ever in doubt just console.log things, its the way the query returnds */}
        {/* So we are doing a bit of destructuring to get just the node object from the item */}
        <ProductList>
          {RECOMMENDED_MENU.map(menu => (
            <Product {...menu} />
          ))}
        </ProductList>
      </div>
    </Section>
  )
};

export const ProductList = styled.div`
  margin: 3rem 0;
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 3rem;

  // @media (min-width: 576px) {
  //   grid-template-columns: 95%;
  // }

  // @media (min-width: 776px) {
  //   grid-template-columns: 80%;
  //   justify-content: center;
  // }

  // @media (min-width: 992px) {
  //   /* 1fr 1fr -- working with fractions, so this means it would be 2 coloumns */
  //   grid-template-columns: 1fr 1fr;
  //   grid-gap: 2rem;
  // }
`

export default RecomendedMenu;
