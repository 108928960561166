import React from "react"
import { Link, graphql } from "gatsby"
// import { connect } from "react-redux"
// import { createStructuredSelector } from "reselect"
import styled from "styled-components";

import Layout from "../components/Layout/layout"
import SEO from "../components/Layout/seo"
// import { getExamplesData } from "../redux/Home/action"
// import { selectExamplesData } from "../redux/Home/selectors"
import { BannerButton, HomeHeader, HomeBanner } from '../lib/utils'
import Gallery from "../components/HomepageComponents/Gallery";
import Banner from "../components/general/Banner";
import RecommendedMenu from "../components/HomepageComponents/RecommendedMenu";
import image from "../images/BannerBPK3.jpg"
import FindUs from "../components/HomepageComponents/FindUs";

const BannerButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const IndexPage = ({ data }) => {
  // useEffect(() => {
  //   onGetExamplesData()
  // }, [onGetExamplesData])
  return (
    <Layout data={data}>
      <SEO title="BPK di Batam | Babi Panggang Karo Batam" />
      <HomeHeader image={image}>
        <HomeBanner
          title={data.site.siteMetadata.title}
          subtitle1="Salah satu BPK TERBAIK di Batam"
          // subtitle2={{ address: data.site.siteMetadata.address, phone: data.site.siteMetadata.phone }}
        >
          <BannerButtonWrapper>
            <Link to={data.site.siteMetadata.gostoreLink} style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
              <BannerButton style={{ margin: "1.7rem 0.8rem" }}>
                Pesan Online
              </BannerButton>
            </Link>
          </BannerButtonWrapper>
        </HomeBanner>
      </HomeHeader>
      <Banner />
      <Gallery />
      <RecommendedMenu />
      <FindUs data={data} />
    </Layout>
  )
};

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
        description
        title
        address
        phone
        grabfoodLink
        gofoodLink
        gostoreLink
        facebook {
          label
          link
        }
        instagram {
          label
          link
        }
      }
    }
  }
`;

// IndexPage.propTypes = {
//   onGetExamplesData: func.isRequired,
// };

// export const mapStateToProps = createStructuredSelector({
//   examplesData: selectExamplesData(),
// });

// export const mapDispatchToProps = {
//   onGetExamplesData: getExamplesData,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(IndexPage)
export default IndexPage;
