import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { styles, Section, Title, SectionButton } from "../../lib/utils"
import Image from "../Layout/image";

const GALLERY_MENU = [
  {
    id: 1,
    name: 'Babi Panggang Karo',
    imgID: 'MenuBPK',
  },
  {
    id: 2,
    name: 'Kidu-Kidu',
    imgID: 'MenuKidu',
  },
  {
    id: 3,
    name: 'Sup Isi B2',
    imgID: 'MenuSoup',
  },
  {
    id: 4,
    name: 'Ikan Mas Arsik',
    imgID: 'MenuIkan',
  },
  {
    id: 5,
    name: 'Babi Goreng',
    imgID: 'MenuB2Goreng',
  },
];

export default function Gallery() {
  return (
    <Section>
      <Title title="menu galeri" />
      <GalleryWrapper>
        {/* in this case, each item has a node object, which is the way gatsby graphql returns... if you're ever in doubt just console.log things, its the way the query returnds */}
        {/* So we are doing a bit of destructuring to get just the node object from the item */}
        {GALLERY_MENU.map((menu, index) => (
          <div className={`item item-${index + 1}`} key={menu.id}>
            <Image imgID={menu.imgID} />
            {/* these are hardcoded, picture stored locally */}
            {/* If we were using contentful, we could supply any additional info below */}
            {/* but in this case since the photos are from a folder, we would need an arry ot descrptions to loop over as well */}
            <p className="info">{menu.name}</p>
          </div>
        ))}
      </GalleryWrapper>
      <Link to="/menu" style={{ textDecoration: "none" }}>
        {/* We define the generic / default styles in the SectionButton, but do all the 'positioning' styles inline like below */}
        <SectionButton style={{ margin: "2rem auto" }}>Lihat Semua Menu</SectionButton>
      </Link>
    </Section>
  )
}
// We utilize CSS GRIDS
const GalleryWrapper = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 1rem;
  .item {
    position: relative;
  }

  .info {
    position: absolute;
    top: 0;
    left: 0;
    background: ${styles.colors.mainYellow};
    padding: 0.1rem 0.3rem;
    text-transform: capitalize;
    border-radius: 0px 20px 20px 0px;
    font-weight: 500;
  }
  /* above is for smallest screen size, below starts at 576 */
  /* so in other words, if min width is 576 or greater */
  @media (min-width: 576px) {
    /* below is fractions, so its 2 columns */
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
  }
  @media (min-width: 768px) {
    /* below we use repeat function, which repeates 1fr 3 times */
    /* same as writing grid-template-columns: 1fr 1fr 1fr; */
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 992px) {
    .gatsby-image-wrapper {
      height: 100%;
    }
    grid-template-areas:
      "one one two two"
      "one one three three";
    .item-1 {
      grid-area: one;
    }
    .item-2 {
      grid-area: two;
    }
    .item-3 {
      grid-area: three;
    }
  }
`
